<template>
    <div>
        <div style="padding:40px">
            <div style="display:flex;margin-bottom:20px">
                <el-input placeholder="请输入客户姓名" class="inPut" v-model="query.crmName"></el-input>
                <el-input placeholder="请输入签单人" class="inPut" v-model="query.signName"></el-input>
                <el-date-picker
                    v-model="v1"
                    class="inPut"
                    type="daterange"
                    range-separator="至"
                    @change="disposeTime"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    :clearable="false"
                    >
                </el-date-picker>
                <el-cascader
                    class="mr10"
                    placeholder="请选择地址"
                    @change="changeAddress"
                    :options="pcaTextArr"
                    v-model="address">
                </el-cascader>
                 <!-- 选择部门 -->
                 <el-cascader
                    class="mr10"
                    collapse-tags
                    collapse-tags-tooltip
                    clearable
                    v-model="query.deptId"
                    placeholder="请选择部门"
                    :props="{
                    value: 'id',
                    label: 'name',
                    children: 'children',
                    multiple: true}"
                    :options="deptList"/>
                <el-select placeholder="请选择合同状态" v-model="query.contractState"  class="inPut">
                    <el-option v-for="(item,i) in contractStateList" :key="i" :value="item.dictValue" :label="item.dictLabel"></el-option>
                </el-select>
                <el-select placeholder="请选择合作类型" v-model="query.signType"  class="inPut">
                    <el-option v-for="(item,i) in signTypeList" :key="i" :value="item.dictValue" :label="item.dictLabel"></el-option>
                </el-select>
                <el-select placeholder="请选择状态" v-model="query.financeState"  class="inPut">
                    <el-option v-for="(item,i) in financeStateList" :key="i" :value="item.dictValue" :label="item.dictLabel"></el-option>
                </el-select>
                <el-button type="primary" @click="getData()">搜索</el-button>
              <el-popconfirm @confirm="exportExcel" title="确定要导出吗？">
                <template #reference>
                  <el-button
                  >导出</el-button
                  >
                </template>
              </el-popconfirm>
            </div>
                <el-table :data="ordersList" border  class="table el-table__cell"
                :row-style="{height:0+'px'}"
                :cell-style="{color:'#909399',fontSize:'12.5px',padding:'0px 0px'}"
                :header-cell-style="{background:'#ffff !important;'}"
                >
                    <el-table-column type="expand">
                        <template #default="scope">
                            <el-table :data="scope.row.crmServiceProcessList" border>
                                <el-table-column label="环节名称" prop="crmServiceSetName"/>
                                <el-table-column label="开始时间" prop="startTime"/>
                                <el-table-column label="完成时间" prop="endTime"/>
                                <el-table-column label="维护人名称" prop="maintainName"/>
                                <el-table-column label="操作">
                                    <template #default>
                                        <el-button  type="text">开始</el-button>
                                        <el-button type="text">完成</el-button>
                                        <el-button type="text">指定维护人</el-button>
                                        <el-button type="text">更新进度</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-table-column>
                    <el-table-column label="客户名称" prop="name"/>
                    <el-table-column label="客户手机号" prop="phone" min-width="110"/>
                    <el-table-column label="客户公司名称" prop="companyName" show-overflow-tooltip="true" min-width="110"/>
                    <el-table-column label="签单时间" prop="signOrderTime" min-width="160"/>
                    <el-table-column label="合作类型" prop="signTypeName"/>
                    <el-table-column label="保证金" prop="earnestMoney"/>
                    <el-table-column label="合作金额" prop="money"/>
                    <el-table-column label="实收金额" prop="practicalMoney"/>
                    <el-table-column label="合同状态" prop="contractStateName"/>
                    <el-table-column label="合同图片" >
                        <template #default="scope" >
                            <el-image class="code"
                                hide-on-click-modal
                                v-if="scope.row.contractImg"
                                :src="scope.row.contractImg"
                                :preview-src-list="scope.row.imgList"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="签单人" prop="signCoachName"/>
                    <el-table-column label="财务确认订单状态" prop="financeStateName"/>
                    <el-table-column label="创建时间" prop="createTime" min-width="160" />
                    <el-table-column label="备注说明" prop="remark" show-overflow-tooltip="true"/>
                    <el-table-column label="操作"  fixed="right">
                        <template #default="scope"> 
                            <el-popover placement="left"  width="150" trigger="click">
                                <div class="space-around" style="text-align: center;">
                                <el-button
                                type="text"
                                @click="editOrder(scope.row)"
                                >
                                编辑
                                </el-button>   
                                <el-button
                                type="text"
                                @click="bindingOrder(scope.row)"
                                >
                                绑定
                                </el-button>
                                <el-button
                                type="text"
                                style="color: red;"
                                @click="returnMoney(scope.row)"
                                >
                                退费
                                </el-button>        
                                </div>        
                                <template #reference>
                                    <el-button size="small"  type="text">操作</el-button>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :total="total"
                    @current-change="handlePageChange"
                    ></el-pagination> 
                </div>
        </div>
        <el-dialog title="绑定环节" v-model="linkDialog" width="800px">
            <el-table :data="LinkList" border>
                <el-table-column label="Id" prop="id"/>
                <el-table-column label="环节" prop="linkName"/>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button type="text" @click="add(scope.row)">添加</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin:10px 0 0 0 ">
                已添加： <el-tag
                        v-for="tag in addLinkList"
                        :key="tag.id"
                        class="mx-1"
                        closable
                        @close="handleClose(tag)"
                        >
                        {{ tag.linkName}}
                    </el-tag>
            </div>
            <template #footer>
                <el-button>取消</el-button>
                <el-button type="primary" @click="confirmAdd">确定</el-button>
            </template>
        </el-dialog>
        <return-money ref="returnMoney"/>
    </div>
</template>
<script>
import {pageOrderList,selectBindingLink,addCrmServiceProcess,exportExcel
    // beginOrAccomplishLink
} from "@/api/attract.js"
import { pcaTextArr } from 'element-china-area-data'
import {listXcxDept} from "@/api/dept";
import {selectDictByType} from "@/api/dict.js"
import ReturnMoney from "./components/ReturnMoney.vue";
    export default{ 
        data(){
            return{ 
                pcaTextArr,
                deptList:[],
                addLinkList:[],
                LinkList:[],
                linkDialog:false,
                v1:[],
                ordersList:[],
                query:{
                    crmName:'',
                    contractState:null,
                    endTime:'',
                    deptId:null,
                    financeState:null,
                    pageIndex:1,
                    pageSize:10,
                    signName:'',
                    signType:null,
                    startTime:'',
                },
                parmas:{
                    crmServiceSetIdList:[],
                    crmOrdersId:null,
                },
                total:null,
                current:1,
                contractStateList:[],
                financeStateList:[],
                signTypeList:[]
            }
        },
        components: {
            ReturnMoney
        },
        methods:{
            returnMoney(item){
                this.$refs.returnMoney.openDialog(item);
            },
            exportExcel() {
                exportExcel(this.query)
            },
            getData(){
              if (this.query.deptId!=null){
                this.query.deptId = [...this.query.deptId.flat()];
              }
                pageOrderList(this.query).then(res=>{ 
                    if(res.code == 200){ 
                        this.ordersList = res.data.records
                        this.ordersList.forEach(e => {
                           e.imgList = []
                           e.imgList.push(e.contractImg)       
                        });
                        this.total = res.data.total            
                    }else{
                        this.$message.error('请求失败！')
                    }
                })
            },
            async getState(){
                //合同状态
               await selectDictByType('contract').then(res=>{ 
                   if(res.code == 200){ 
                    this.contractStateList = res.data
                   }
                })
                //合作类型
               await selectDictByType('signType').then(res=>{ 
                    if(res.code == 200){ 
                    this.signTypeList = res.data
                   }
                })
                //
               await selectDictByType('financeState').then(res=>{ 
                    if(res.code == 200){ 
                    this.financeStateList = res.data
                   }
                })
                await listXcxDept().then(res => {
                    if(res.code == 200){ 
                         this.deptList = this.getTypeList(res.data);    
                    }
                   
                })   
            },
            getTypeList(commodityType) {
                if (commodityType!=null){
                    commodityType.forEach(items => {
                    if (items!=null){
                        if (items.children!=null && items.children.length > 0) {
                        this.getTypeList(items.children);
                        } else {
                        items.children = undefined;
                        }
                    }
                    });
                    return commodityType;
                }
            },
            disposeTime(){
                if(this.v1.length>=2){
                this.query.startTime = this.dateFormat(this.v1[0]);
                this.query.endTime = this.dateFormat(this.v1[1]);
                }
            },
            dateFormat(val) {
                var date = new Date(val);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                var d = date.getDate();
                d = d < 10 ? "0" + d : d;
                const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
                return time;
            },
            editOrder(row){ 
                console.log(row);
            },
            bindingOrder(row){ 
                this.parmas.crmOrdersId = row.id
                this.linkDialog = true
                selectBindingLink(row.id).then(res=>{ 
                    if(res.code == 200){ 
                        this.LinkList = res.data
                    }else{ 
                        this.$$message.error('请求数据失败！')
                    }
                })
            },
            add(row){ 
                this.addLinkList.push(row)
            },
            handleClose(e){ 
                this.addLinkList.splice(this.addLinkList.indexOf(e), 1)
            },
            async confirmAdd(){
                this.addLinkList.forEach(item=>{ 
                    this.parmas.crmServiceSetIdList.push(item.id)
                })
              await addCrmServiceProcess(this.parmas.crmOrdersId,this.parmas.crmServiceSetIdList).then(res=>{ 
                    this.linkDialog = false
                    if(res.code == 200){ 
                        this.$message.success('添加成功！')
                    }else{
                        this.$message.error('添加失败')
                    }
                })
            },
            handlePageChange(e){ 
                this.query.pageIndex = e
                this.getData()
            }
        },
        created(){ 
            this.getData()
            this.getState()
        }
    }

</script>    
<style scope >
.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}
.el-table__cell {
  position: static !important;
}
.inPut{
    margin: 0 10px;
    width:260px
}
.mx-1{ 
    margin: 5px;
}
.mr10{ 
    margin-right: 10px;
}
.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}
</style>
<style  type="less">
/* CJL TEST
1.重写plus的image图片预览大图的的position定位样式为static !important;
2.需在el-table中手动添加要覆盖的class名 如el-table__cell
3.此css代码需放在全局的less的style节点中 */
.el-table__cell {
  position: static !important;
}

</style>